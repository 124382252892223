import React, { useState, useEffect } from "react"
import "./App.css"

function App() {
	const [text, setText] = useState("Breathing Exercise")

	useEffect(() => {
		let container = document.getElementById("main")
		let totalTime = 7500
		let breatheTime = (totalTime / 5) * 2
		let holdTime = totalTime / 5
		setText("Breathe In")
		container.className = "container grow"
		setTimeout(() => {
			setText("Hold")
			setTimeout(() => {
				setText("Breathe Out")
				container.className = "container shrink"
			}, holdTime)
		}, breatheTime)
		setInterval(() => {
			setText("Breathe In")
			container.className = "container grow"
			setTimeout(() => {
				setText("Hold")
				setTimeout(() => {
					setText("Breathe Out")
					container.className = "container shrink"
				}, holdTime)
			}, breatheTime)
		}, totalTime)
	}, [])

	return (
		<div id="main" className="container">
			<div className="circle"></div>
			<p id="text">{text}</p>

			<div className="pointerContainer">
				<div className="pointer"></div>
			</div>

			<div className="gradientCircle"></div>
		</div>
	)
}

export default App
